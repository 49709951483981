// about me asset
import aboutme from '../assets/about_me.webp'

// work exp assets
import cred from '../assets/work_experience_cred.webp'
import fampay from '../assets/work_experience_fampay.webp'
import atom from '../assets/work_experience_atom.webp'
import spike from '../assets/work_experience_spike.webp'
import nearcast from '../assets/work_experience_nearcast.webp'
import winuall from '../assets/work_experience_winuall.webp'

// project assets
import thedololist from '../assets/project_the_do_list.webp'
import projectyuva from '../assets/project_yuva.webp'
import manipur from '../assets/project_manipur.webp'
import werk from '../assets/project_werk.webp'
import spikeproject from '../assets/project_spike.webp'
import sociocredz from '../assets/project_sociocredz.webp'
import devtalks from '../assets/project_devtalks.webp'
import easygists from '../assets/project_easygists.webp'
import athena from '../assets/project_athena.webp'

// blog assets
import gradle from '../assets/blog_gradle.webp'
import service from '../assets/blog_service.webp'

// hobbies assets
import hobbies from '../assets/hobbies.webp'

export const sectionData = [
    // about me
    {
        key: "about_me",
        type: "image",
        image: aboutme,
    },
    // work exp
    {
        key: "work_exp",
        type: "image_carousel",
        scrollAxis: "horizontal",
        items: [
            {
                image: cred,
                url: "https://cred.club/",
            },
            {
                image: spike,
            },
            {
                image: fampay,
                url: "https://www.famapp.in/",
            },
            {
                image: atom,
                url: "https://www.atomei.app/",
            },
            {
                image: nearcast,
                url: "https://www.nearcast.com/",
            },
            {
                image: winuall,
                url: "https://www.winuall.com/",
            },
        ]
    },
    // blogs
    {
        key: "blogs",
        type: "image_carousel",
        scrollAxis: "horizontal",
        items: [
            {
                image: gradle,
                url: "https://medium.com/better-programming/custom-gradle-tasks-build-and-send-your-apk-to-telegram-42f7741cd27e",
            },
            {
                image: service,
                url: "https://medium.com/better-programming/implementing-a-foreground-service-in-android-building-a-stopwatch-app-5a1da85d929f"
            },
        ]
    },
    // projects
    {
        key: "projects",
        type: "image_carousel",
        scrollAxis: "vertical",
        items: [
            {
                image: thedololist,
                url: "https://play.google.com/store/apps/details?id=in.rithikjain.thedolist",
            },
            {
                image: projectyuva,
                url: "https://play.google.com/store/apps/details?id=com.dscvit.yuva",
            },
            {
                image: manipur,
                url: "https://github.com/rithikjain/police-watch-app",
            },
            {
                image: werk,
                url: "https://play.google.com/store/apps/details?id=com.dscvit.werk"
            },
            {
                image: spikeproject,
            },
            {
                image: sociocredz,
                url: "https://github.com/TeamRekursion/sociocredz-app",
            },
            {
                image: devtalks,
                url: "https://play.google.com/store/apps/details?id=com.dscvit.devtalks",
            },
            {
                image: easygists,
                url: "https://play.google.com/store/apps/details?id=com.rithikjain.projectgists",
            },
            {
                image: athena,
            },
        ]
    },
    // hobbies
    {
        key: "hobbies",
        type: "image",
        image: hobbies,
    },
    // contact me
    {
        key: "contact_me",
        type: "contact_me",
    },
]