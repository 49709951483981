import { sectionData } from './data/sectionsData';
import SectionImage from './components/SectionImage';
import SectionImageCarousel from './components/SectionImageCarousel';
import SectionContactMe from './components/SectionContactMe';

function App() {
  return (
    <div className="my-8 md:my-32 w-5/6 md:w-4/5 grid grid-cols-1 md:grid-cols-2 mx-auto">
      {sectionData.map((section) => {
        if (section.type === "image") {
          return <SectionImage
            key={section.key}
            image={section.image}
            className="w-full h-auto" />
        } else if (section.type === "image_carousel") {
          return <SectionImageCarousel
            key={section.key}
            className="w-full h-auto"
            section={section} />
        } else if (section.type === "contact_me") {
          return <SectionContactMe
            className="w-full h-auto"
            key={section.key} />
        }
        else {
          return <div />
        }
      })}
    </div>
  );
}

export default App;
