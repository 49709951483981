import contactme from '../assets/contact_me.webp';
import { ImageMap } from '@qiuz/react-image-map';

const mapArea = [
    {
        id: 'email',
        left: '22.55%',
        top: '66.4%',
        height: '13.33%',
        width: '13.33%',
        style: { cursor: 'pointer' },
    },
    {
        id: 'linkedin',
        left: '43.33%',
        top: '66.4%',
        height: '13.33%',
        width: '13.33%',
        style: { cursor: 'pointer' },
    },
    {
        id: 'whatsapp',
        left: '64.11%',
        top: '66.4%',
        height: '13.33%',
        width: '13.33%',
        style: { cursor: 'pointer' },
    }
]

const onMapClick = (area, index) => {
    switch (area.id) {
        case "email":
            window.open("mailto:rithik.jain3006@gmail.com", "_blank");
            break;

        case "linkedin":
            window.open("https://www.linkedin.com/in/rithik-jain-710b3a199/", "_blank");
            break;

        case "whatsapp":
            window.open("https://api.whatsapp.com/send?phone=918197513731", "_blank");
            break;

        default:
            break;
    }
}

const SectionContactMe = () => (
    <ImageMap
        className='w-full h-auto'
        map={mapArea}
        src={contactme}
        onMapClick={onMapClick}
        alt="Contact Me" />
)

export default SectionContactMe
