import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const SectionImageCarousel = ({ section }) => {
    return (
        <Carousel
            key={section.key}
            autoPlay={true}
            axis={section.scrollAxis}
            infiniteLoop={true}
            interval={5000}
            dynamicHeight={false}
            showArrows={true}
            swipeable={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            className='w-full h-auto'>
            {section.items.map((item) => {
                const image = <img key={item.image} className='w-full h-auto block' src={item.image} alt="" />
                return (item.url ? <a key={item.image} className="inline-block" target='_blank' rel="noreferrer" href={item.url}>{image}</a> : image)
            })}
        </Carousel>
    )
};

export default SectionImageCarousel;
